import React from 'react';
import './Modal.css';
const Modal = ({open, onClose, languages, tools, exp}) => {

    if (!open) return null

    console.log(languages);
    return (
        
        <div onClick={onClose} className='overlay'>
          <div className='modalContainer'>
            <p onClick={onClose} className='closeBtn'>X</p>
            <div className='content'>
              <h2>{exp}</h2>
              <hr />
              <br />
              <h3><u>Tools</u>:</h3>
              <ul>
                {
                  tools.map((item, index) => (<li key={index}>{item}</li>))
                }
              </ul>
              <br />
              <h3><u>Programming Languages</u>:</h3>
              <ul>
                {
                  languages.map((item, index) => (<li key={index}>{item}</li>))
                }
              </ul>
            </div>
            
          </div>
        </div>
    )
}

export default Modal;