import React from "react";
import "./Home.css";
import Footer from "./Footer";

function Home() {
  return (
    <div className="home-body">
      <div className="home-area">
        <div className="intro">
          <h1>Hi, I'm Dean.</h1>
          <h3>Software Developer and Automation Entusiast </h3>
          <hr />
          <p>
            Graduating with a degree in Web Development, I found where my interests were shortly after. Transferring from the Security Operations Center at the company I had worked at the time to the Software Developmet team, I soon started to work on tools that utilize skillsets from data analysis and automation to lighten the workload of the security analysts. While I am no longer with that company, I am eternally greatful for opening my eyes to using data and automation to improve the day to day life of everyone.
          </p>
          <p></p>
        </div>
        <br />

        <div className="intro2">

            <h3>It's all about the Story.</h3>
            <hr />
            <p>
              I love learning new things, especially if it is technology related. Outside of learning new things, I really enjoy a great story and invest a majority of my free time reading fantasy novels, playing video games, watching anime, or creating my own stories through writing and tabletop games like Dungeons & Dragons. It inspires me to hope that one day, my work will have a positive effect on the lives of others.
            </p>

        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Home;
