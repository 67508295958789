import React from "react";
import "./About.css";
import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import Footer from "./Footer";
import ContactForm from "./ContactForm";



function About({openExpModal, openKnowModal, openFamModal}) {

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },

  }));
  const classes = useStyles();
  

  return (

    <div className="about-body">

      <div className="about-area">
        <div className="about-intro">
          <h2>Hello,</h2>
          <h4>
            If you clicked on the "About" link, I am assuming you would like to know a bit more about my capabilities. 
            <br />Listed below I have categorized my skills by how much experience I have in each.
          </h4>
          
        </div>
        <div class="about-skills">
        <h3>Skills</h3>
          <hr />
          <Grid container className={classes.root} spacing={2}>
        
            <Grid item xs={12} lg={3}>
              <div className='modal-button'>
              <button onClick={openExpModal}>Experienced</button>
      
              </div>
            </Grid>
            <Grid item xs={12} lg={9}>
              <div class='skilldesc'>Worked with on multiple projects or had long term exposure through work related duties.</div>
            </Grid>
            <Grid item xs={12} lg={3}>
            <div class='modal-button'><button onClick={openKnowModal}>Knowledgeable</button></div>
            </Grid>
            <Grid item xs={12} lg={9}>
              <div class='skilldesc'>Worked with in at least one project or exposure through training/course work (non-work related experience).</div>
            </Grid>
            <Grid item xs={12} lg={3}>
            <div class='modal-button'><button onClick={openFamModal}>Familiar</button></div>
            </Grid>
            <Grid item xs={12} lg={9}>
              <div class='skilldesc'>Have done some research and self study and have a rudimentary understanding.</div>
            </Grid>

          </Grid>
          <br />
          <br />
          <br />
          <br />
          <br />
          <h3>Contact Me</h3>
          <hr />
          <ContactForm />

        </div>

        </div>
      <Footer />
    </div>
  );
}

export default About;
