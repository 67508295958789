import React, {useState} from "react";
import Navbar from "./Navbar";
import Home from "./Home";
import About from "./About";
import Portfolio from "./Portfolio";
import Modal from "./Modal";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

function App() {
  const [openExpModal, setOpenExpModal] = useState(false);
  const [openKnowModal, setOpenKnowModal] = useState(false);
  const [openFamModal, setOpenFamModal] = useState(false);

  const langExp = ["Python", "JavaScript", "Node.js", "Node-RED", "MySQL", "JSON"];
  const langKnow = ["TypeScript", "React", "JQuery", "Express.js", "Java", "Lua", "PowerShell", "Bash", "CSS Preprocessors (LESS/SASS)", "Other SQL formats (MS SQL, Oracle, PostgreSQL)"];
  const langFam = ["Go", "Dart", "Kotlin", "PHP"];
  
  const toolsExp = ["Microsoft Excel", "Microsoft Word", "Microsoft Forms", "Microsoft Power Automate", "Microsoft Sharepoint", "Microsoft Windows", "MacOS", "LogRhythm (Certifications)", "Splunk Enterprise Security (Fundamentals Certification)", "Carbon Black", "Symantec (Endpoint Protection/Data Loss Prevention)", "Jira", "ServiceNow", "Ironscales", "Git/Gitlab", "Postman", "VSCode"]; 
  const toolsKnow = ["Microsoft Powerpoint", "ServiceNow Administration/IAM", "Salesforce", "Android Studio","Microsoft Powerpoint", "Microsoft PowerBI", "Microsoft Azure", "Microsoft Sentinel", "Tableau", "LogRhythm Administration Console (Certification)", "Amazon Web Services", "Cisco Networking", "Google Firebase", "Linux(Kali, Ubuntu, OpenSUSE)", "Wireshark", "Automation Anywhere (Certification)", "Docker"]; 
  const toolsFam = ["UIPath", "Appian", "Blue Prism", "Ansible", "Terraform", "Kubernetes"]; 
  const openExp = () => {setOpenExpModal(true)};
  const openKnow = () => {setOpenKnowModal(true)};
  const openFam = () => {setOpenFamModal(true)};

  return (
    <div className="App">
    <Modal open={openExpModal} onClose={() => setOpenExpModal(false)} languages={langExp} tools={toolsExp} exp='Experienced' />
    <Modal open={openKnowModal} onClose={() => setOpenKnowModal(false)} languages={langKnow} tools={toolsKnow} exp='Knowledgeable' />
    <Modal open={openFamModal} onClose={() => setOpenFamModal(false)} languages={langFam} tools={toolsFam} exp='Familiar' />
      <Router>
        <Navbar />
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route 
            path="/about"
            render={(props) => (
              <About {...props} openExpModal={openExp} openKnowModal={openKnow} openFamModal={openFam}/>
            )}>
          
          </Route>
          <Route path="/portfolio">
          <Portfolio />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
