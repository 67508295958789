import React from "react";
import "./Navbar.css";
import { NavLink } from "react-router-dom";

function Navbar() {
  return (
    <div className="nav-bar">
    <a href="/">
      <div className="nav-logo">
        <p className="otaku">Otaku</p>
        <p className="logic">Logic</p>
      </div>
      </a>
      <div className="nav-tray">
        <NavLink
          className="nav-item"
          to="/"
        >
          Home
        </NavLink>{" "}
        <NavLink
          className="nav-item"
          activeClassName="nav-item-selected"
          to="/about"
        >
          About
        </NavLink>{" "}
        <NavLink
          className="nav-item"
          activeClassName="nav-item-selected"
          to="/portfolio"
        >
          Portfolio
        </NavLink>
      </div>
    </div>
  );
}

export default Navbar;
