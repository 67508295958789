import React from "react";
import "./Portfolio.css";
import Footer from "./Footer";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import LanguageIcon from '@material-ui/icons/Language';

function Portfolio() {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      flexWrap: "wrap",
      backgroundColor: 'transparent',
      "& > *": {
        margin: theme.spacing(3),
        width: theme.spacing(30),
        height: theme.spacing(30),
      },
    },
  }));

  const classes = useStyles();

  return (
    <div className="port-body">

      <div className="port-area">
        <h1>Projects</h1>
        <div className="port-grid">
          <div className={classes.root}>
            <Paper elevation={15}>
              <div className="port-display">
               <img src="https://i.etsystatic.com/isla/b8953c/43474161/isla_500x500.43474161_j4pvom8j.jpg?version=0" alt="jadore"></img>
               <div className="link">
                 <a href="http://www.jadorecrafts.com/" rel="noreferrer noopener" target="_blank"><LanguageIcon fontSize="large" /></a>
                 <p>J'Adore Crafts</p>
               </div>
              </div>
            </Paper>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Portfolio;
