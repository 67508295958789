import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './ContactForm.css';

const ContactForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    // Your email.js service ID, template ID, and user ID
    const serviceId = 'service_e6ljxlg';
    const templateId = 'template_oxw2z9d';
    const userId = '8k6UCvqtoDcqhP9bt';

    // Send the email
    emailjs.sendForm(serviceId, templateId, e.target, userId)
      .then((response) => {
        console.log('Email sent successfully!', response);
      })
      .catch((error) => {
        console.error('Error sending email:', error);
      });

    // Clear the form
    setName('');
    setEmail('');
    setMessage('');
  };

  return (
    <div className='contact-form-container'>
      <form className='contact-form' onSubmit={handleSubmit}>
        <div>
          <label>Name:</label>
          <input type="text" name="name" value={name} onChange={(e) => setName(e.target.value)} required />
        </div>
        <div>
          <label>Email:</label>
          <input type="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
        </div>
        <div>
          <label className="message-label" >Message:</label>
          <textarea className="message-textarea" name="message" value={message} onChange={(e) => setMessage(e.target.value)} required />
        </div>
        <button type="submit">Send Email</button>
      </form>
    </div>
  );
};

export default ContactForm;