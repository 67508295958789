import React from "react";
import "./Footer.css";
import logo from "../logo.svg";
import Grid from "@material-ui/core/Grid";
// import { NavLink } from "react-router-dom";
import FacebookIcon from '@material-ui/icons/Facebook';
import GitHubIcon from '@material-ui/icons/GitHub';
// import CodeIcon from '@material-ui/icons/Code';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

function copyrightYear() {
  const cyear = new Date().getFullYear();
  return cyear;
}

function Footer() {
  return (
    <footer className="App-footer">
      {" "}
      <Grid container spacing={0}>
        <Grid item xs={1}>
          <img src={logo} className="App-logo" alt="logo" />
        </Grid>
        <Grid container item xs={10}>
          <Grid item xs={4}></Grid>
          <Grid item xs={4}>
          <div className="footer-block">
          <div className="footer-info">
          <a className="footer-link" href="https://github.com/dben0817" rel="noreferrer noopener" target="_blank"><GitHubIcon /></a>
            <a className="footer-link" href="https://www.facebook.com/groups/641251976762216" rel="noreferrer noopener" target="_blank"><FacebookIcon /></a>
            <a className="footer-link" href="https://www.linkedin.com/in/dben0817" rel="noreferrer noopener" target="_blank"><LinkedInIcon /></a>
            {/* <a className="footer-link" href="https://www.hackerrank.com/dben0817" rel="noreferrer noopener" target="_blank"><CodeIcon /></a> */}
          </div>

            <p className="footer-cr">
              OtakuLogic Designs Copyright © {copyrightYear()}
            </p>
          </div>
          </Grid>
          <Grid item xs={4}>
          <div className="footer-block">
          <p className="footer-info lg">Quick Links:</p>
          <div className="footer-nav">
            <a className="footer-link lg" href="/">Home</a>
              <a className="footer-link lg" href="/about">About</a>
              <a className="footer-link lg" href="/portfolio">Portfolio</a>
          </div>
          </div>
          </Grid>
        </Grid>
        <Grid item xs={1}>
          <img src={logo} className="App-logo" alt="logo" />
        </Grid>
      </Grid>
    </footer>
  );
}

export default Footer;
